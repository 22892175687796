const swiperTop = new Swiper (".top__slider", {
    loop: true,
    effect: "fade",
    fadeEffect: {
        crossFade: true
    },
    autoplay: {
        delay: 4500
    },
    autoplayDisableOnInteraction: false,
    slidesPerView: 2,
    pagination: {
        el: ".swiper-pagination",
        clickable: true
    },
});