(function() {
    const offsetBottom = document.body.offsetHeight;
    const footerHeight = document.querySelector(".site-footer").offsetHeight;
    const boncrepeCategoryConWrap = document.querySelector(".js-boncrepe-menu__category-con-wrap");
    const boncrepeCategoryCon = document.querySelector(".js-boncrepe-menu__category-con");
    
    let prevScrollpos = window.pageYOffset;

    window.onscroll = function() {
        let currentScrollPos = window.pageYOffset;
        if(prevScrollpos > currentScrollPos || currentScrollPos <= 80) {
            document.querySelector(".js-header").style.top = "0";
            if(boncrepeCategoryConWrap) {
                boncrepeCategoryConWrap.style.top = "80px";
            }
        } else {
            document.querySelector(".js-header").style.top = "-80px";
            if(boncrepeCategoryConWrap) {
                boncrepeCategoryConWrap.style.top = "0";
            }
        }
        prevScrollpos = currentScrollPos;


        if(boncrepeCategoryConWrap) {
            const ctaHeight = document.querySelector(".cta").offsetHeight;
            // TODO: needs to fix dynamically
            if(currentScrollPos > 535 && currentScrollPos < (offsetBottom - (80 + footerHeight + ctaHeight))) {
                boncrepeCategoryConWrap.classList.add("boncrepe-menu__category-con-wrap_active");
                boncrepeCategoryCon.classList.add("boncrepe-menu__category-con_active");
            } else {
                boncrepeCategoryConWrap.classList.remove("boncrepe-menu__category-con-wrap_active");
                boncrepeCategoryCon.classList.remove("boncrepe-menu__category-con_active");
            }
        }
    };
})();