(function () {
    if(document.querySelector(".js-modal")) {
        const modal = document.querySelector(".js-modal");

        function setCookie(name, value, exd) {
            let d = new Date();
            d.setTime(d.getTime() + exd*24*60*60*1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie = name + "=" + value + ";" + expires + ";path=/";
        }
    
        function getCookie(name) {
            const cookieName = name + "=";
            const decodedCookie = decodeURIComponent(document.cookie);
            let cArr = decodedCookie.split(";");
            for (let i = 0; i < cArr.length; i++) {
                let c = cArr[i];
                while(c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if(c.indexOf(cookieName) == 0) {
                    return c.substring(cookieName.length, c.length);
                }
            }
            return "";
        }
    
        function showInfoModal() {
            const result = getCookie("modal-trigger");
    
            if(result == "") {
                setTimeout(() => {
                    modal.classList.remove("modal_disabled");
                }, 5000);
            } else {
                return;
            }
        }
    
        function hideModal() {
            let d = new Date();
            d = d.toUTCString();
            modal.classList.add("modal_disabled");
            setCookie("modal-trigger", "modal_" + d, 1);
        }
    
        window.onload = showInfoModal();
        document.querySelector(".js-modal__overlay").addEventListener("click", hideModal);
        document.querySelector(".js-modal__close").addEventListener("click", hideModal);
    }
})();