(function () {
	const headerMobileWrap = document.querySelector(".js-header__mobile-wrap");
	const headerMobileOverlay = document.querySelector(".js-header__mobile-overlay");
	const headerMobileMenuWrap = document.querySelector(".js-header__mobile-menu-wrap");
	const headerBtnClose = document.querySelector(".js-header__close");
	const headerBtnCTA = document.querySelector(".js-header__mobile-cta-button");

	if(document.querySelector(".js-header__toggle")) {
		document.querySelector(".js-header__toggle").addEventListener("click", function() {
			headerMobileWrap.classList.add("header__mobile-wrap-active");
			headerMobileOverlay.classList.add("header__mobile-overlay-active");
			headerMobileMenuWrap.classList.add("header__mobile-menu-wrap-active");
			headerBtnClose.classList.add("header__close-active");
			document.querySelector("body").style.overflow = "hidden";
		});
	}

	if(headerBtnClose) {
		function closeDrawer() {
			headerMobileWrap.classList.remove("header__mobile-wrap-active");
			headerMobileOverlay.classList.remove("header__mobile-overlay-active");
			headerMobileMenuWrap.classList.remove("header__mobile-menu-wrap-active");
			headerBtnClose.classList.remove("header__close-active");
			document.querySelector("body").style.overflow = "auto";
		}

		headerBtnCTA.addEventListener("click", closeDrawer);
		headerBtnClose.addEventListener("click", closeDrawer);
	}
})();
