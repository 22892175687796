const swiperNewItem = new Swiper(".swiper-container.new-item__slider", {
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    breakpoints: {
        640: {
            slidesPerView: 2,
            spaceBetween: 20,
        }, 
        900: {
            slidesPerView: 3,
            spaceBetween: 32,
        },
        1024: {
            slidesPerView: 4,
        }
    },
    navigation: {
        nextEl: ".new-item__slider-next",
        prevEl: ".new-item__slider-prev",
    }
});