(function() {
    var imgLazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    let twtLazy = false;
    const elmsLazy = document.querySelectorAll(".lazy-anm");
    const elmsLazyArr = Array.prototype.slice.call(elmsLazy, 0);
    const options = {
        root: null,
        rootMargin: "0px 55%",
        threshold: 0
    };

    let observer = new IntersectionObserver(lazyTrigger, options);
    elmsLazyArr.forEach(function(elmLazy) {
        observer.observe(elmLazy);
    });

    function lazyTrigger(entries) {
        // console.log(entries);
        const entriesArr = Array.prototype.slice.call(entries, 0);
        entriesArr.forEach(function(entry) {
            if(entry.isIntersecting) {
                entry.target.classList.add("loaded");
                onLazyLoad();
            }
        });
    }
    
    function onLazyLoad() {
        if(twtLazy === false) {
            twtLazy = true;
            twitterLazy();
        }
    }

    function twitterLazy() {
        let ad = document.createElement("script");
        ad.type = "text/javascript";
        ad.async = true;
        ad.src = "https://platform.twitter.com/widgets.js";
        const sc = document.getElementsByTagName("script")[0];
        sc.parentNode.insertBefore(ad, sc);
    }
})();

(function(w, d){
    var b = d.getElementsByTagName("body")[0]; 
    var s = d.createElement("script");
    var v = !("IntersectionObserver" in w) ? "8.8.0" : "10.10.0";
    s.async = true;
    s.src = "https://cdnjs.cloudflare.com/ajax/libs/vanilla-lazyload/" + v + "/lazyload.min.js";
    w.lazyLoadOptions = { elements_selector: ".lazy", to_webp:true };
    b.appendChild(s); 
}(window, document));
