const swiperJpLocal = new Swiper(".about-konbiniya__slider .swiper-container", {
    loop: true,
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 8,
    breakpoints: {
        600: {
            slidesPerView: 3,
            slidesPerGroup: 3,
        },
        740: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 16
        },
        960: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 16
        },
        1400: {
            slidesPerView: 6,
            slidesPerGroup: 6,
            spaceBetween: 16
        }
    },
    autoplay: {
        delay: 5000,
    },
    lazy: true,
    navigation: {
        nextEl: ".about-konbiniya__slider-button_next",
        prevEl: ".about-konbiniya__slider-button_prev",
    }
});